@import '~rc-slider/assets/index.css';
@import '~rc-tooltip/assets/bootstrap.css';

.pc-range-slider {
  &.rc-slider {
    background-color: transparent;
    &.rc-slider-disabled {
      background-color: transparent;
      .rc-slider-handle {
        background-image: url('../../images/slider/slider.png');
        background-size: 48px;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 40px;
        background-color: transparent;
        border: none;
      }
    }
    &.triangle-handler {
      .rc-slider-handle{
      height: 50px;
      position: absolute;
      margin-left: 0px;
      top: 5px;
      background-color: transparent;
      border: none;
    }
    }
    
    .rc-slider-step, .rc-slider-rail, .rc-slider-track {
      height: 40px;
    }

      .rc-slider-handle {
        margin-top: -5px;
        touch-action: pan-x;
        background-color: transparent;
      }

    .rc-slider-dot {
      width: 12px;
      height: 12px;
      background-color: transparent;
    }
  }

  &.rc-slider-vertical {
    margin-left: 10px;
    .rc-slider-rail {
      height: 100%;
    }
    &.triangle-handler {
      .rc-slider-handle {
        left: -3px;
      }
    }
  }
}